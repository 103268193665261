.container {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  padding: 0 60px;
}

#app {
  display: grid;
  grid-template-areas: "head head"
  "aside main"
  "foot foot";
  grid-template-columns: 280px auto;
  grid-template-rows: 37px auto 45px;
  min-height: 100vh;
}


// HEADER

.page-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  grid-area: head;
  font-size: 16px;
  color: #ffffff;
  background: #08195b;
}

.page-header a {
  color: #ffffff;
}

.page-header a:hover,
.page-header a:focus {
  color: #ffffff;
}


// ASIDE
.page-aside {
  grid-area: aside;
  background: #e9e9e9;
  border-right: 1px solid #bababa;
}

.page-aside .logo-box {
  padding: 40px 30px;
}

.page-aside .logo-box img {
  width: 100%;
}

.nav__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.nav__item.active .nav__link {
  color: #ffffff;
  background: #08195b;
  border-bottom-color: transparent;
}

.nav__link {
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 10px;
  padding-right: 20px;
  padding-left: 30px;
  font-size: 17px;
  color: #07004c;
  border-bottom: 1px solid #bababa;
}

.nav__link:hover,
.nav__link:focus {
  color: #ffffff;
  background: #08195b;
  border-bottom-color: transparent;
}


// MAIN

.page-main {
  grid-area: main;
  padding: 0;
  padding-top: 60px;
}

.page-title {
  margin-bottom: 15px;
}

.page-title h1 {
  margin: 0;
  font-size: 35px;
  font-weight: 400;
}

.page-info {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding-bottom: 15px;
}

.page-info::after {
  content: "";
  position: absolute;
  right: 60px;
  bottom: 0;
  left: 60px;
  height: 1px;
  background: #b8b8b8;
}

.page-subtitle {
  position: relative;
  padding-left: 20px;
  overflow: hidden;
}

.page-subtitle::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #161f5a;
  transform: translateX(-50%) rotate(45deg);
}

.page-subtitle h2 {
  margin: 0;
  font-size: 25px;
  font-weight: 400;
}

label.search-box a {
  font-size: 16px;
}

// Materials
.materials__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.materials__item {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 31%;
  margin-bottom: 3.5%;
  padding: 35px;
  padding-top: 60px;
  padding-bottom: 100px;
  background: #e8e8e8;
  box-shadow: 0 8px 19px 1px rgba(0, 0, 1, 0.3);
}

.materials__item::before {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  z-index: -1;
  border: 1px solid #acacac;
}

.materials__item h5 {
  margin: 0;
  margin-bottom: 25px;
  font-size: 24px;
  text-align: center;
}

.materials__item p {
  margin-bottom: 55px;
  font-size: 20px;
}

.materials-for__list {
  width: auto;
  margin-bottom: 45px;
  padding-left: 20px;
  list-style: disc;
}

.materials-for__list a {
  font-size: 18px;
  line-height: 1.75;
  text-decoration: none;
}

.materials__item .button,
.button--search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 210px;
  height: 40px;
  margin-bottom: 25px;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #acacac;
  box-shadow: 4.096px 2.868px 9.5px 0.5px rgba(14, 15, 15, 0.4);
}

.materials__item .button:first-of-type {
  margin-top: auto;
}

.materials__item .button:hover,
.materials__item .button:focus {
  color: #ffffff;
  background: #161f5a;
  border-color: transparent;
}

.button--search {
  position: fixed;
  bottom: 40px;
  left: 60px;
  max-width: 160px;
  color: #ffffff;
  background: #161f5a;
  border-color: transparent;
}

.button--search:hover,
.button--search:focus {
  color: #161f5a;
  background: #ffffff;
  border: 1px solid #acacac;
}


// FOOTER

.page-footer {
  position: static;
  grid-area: foot;
  font-size: 15px;
  background: #08195b;
  transform: none;
}

.page-footer .container {
  display: flex;
  justify-content: space-between;
}

.portfolio_box {
  font-size: 15px;
}


// Search filter
.search-form {
  width: 100%;
  font-size: 15px;
}

.search-form__filter {
  width: 100%;
}

.search-form__block {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}

.search-form__block label {
  margin-bottom: 0;
  font-size: 15px;
}

.search-form__field {
  position: relative;
  box-sizing: border-box;
  height: 30px;
  margin: 0 8px;
  padding: 5px 10px;
  padding-right: 18px;
  font-family: inherit;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 400;
  color: #787878;
  background: #e7e7e7 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23161f5a' fill-rule='evenodd' class='cls-1' d='M8,0L4.141,6.993,0.012,0.156Z'/%3E%3C/svg%3E%0A") calc(100% - 5px) center no-repeat;
  border: 1px solid #161f5a;
          appearance: none;

  -webkit-appearance: none;
}

.search-form__field:focus {
  color: #787878;
  background: #e7e7e7;
  border: 1px solid #161f5a;
}

.search-form__block--date {
  margin-bottom: 20px;
}

.search-form__block--date .search-form__field {
  width: 115px;
  margin-bottom: 10px;
  padding-right: 0;
  border-radius: 0;
}


// Меняем стили datepicker
.input-group {
  display: inline-flex;
  width: auto;
}

.input-group-append {
  position: absolute;
  top: 0;
  right: 8px;
  width: 20px;
  height: 30px;
}

.input-group-append button {
  display: none;
}


// Search
.tags_form {
  background: #e9e9e9;
}

.form-header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 5px 15px;
  background: #dddddd;
  border: 1px solid #b9b9b9;
}

.form-header.active {
  box-shadow: 0 5px 4.75px 0.25px rgba(6, 7, 7, 0.15);
}

.form-header::after {
  content: "";
  position: absolute;
  right: 15px;
  width: 0;
  height: 0;
  margin-top: -4px;
  border-top: 6px solid #07004c;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #07004c;
  transform: rotate(-135deg);
}

.form-header.active::after {
  right: 10px;
  margin-top: 0;
  transform: rotate(-45deg);
}

.form-header h4 {
  margin: 0;
  margin-top: -5px;
  margin-right: 50px;
  font-size: 25px;
  font-weight: 400;
  color: #07004c;
}

.check-all {
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
}

.check-all input {
  margin-right: 5px;
}

.check-all span {
  font-size: 15px;
}

.form-check-label {
  padding: 0;
}

.tags_form .form-check {
  padding: 0 20px;
}
